$phone: 600px;
$tablet-768: 768px;
$tablet: 1024px;
$laptop: 1280px;
$laptop-large: 1338px;
$desktop: 1525px;

@mixin fontFace($name, $file, $weight: normal, $style: normal){
    @font-face {
        font-family: "#{$name}";
        src: url('./../fonts/#{$file}.eot');
        src: url('./../fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/#{$file}.woff2') format('woff2'),
        url('./../fonts/#{$file}.woff') format('woff'),
        url('./../fonts/#{$file}.ttf') format('truetype'),
        url('./../fonts/#{$file}.svg?#webfont') format('svg');
        font-weight: $weight;
        font-style: $style;
        font-style: $style;
        font-display: swap;
    }
}

@mixin phone {
    @media (min-width: #{$phone}) {
        @content;
    }
}

@mixin max-phone {
    @media (max-width: $phone) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet + 1}) {
        @content;
    }
}

@mixin max-tablet {
    @media (max-width: $tablet) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: #{$laptop}) {
        @content;
    }
}

@mixin laptop-large {
    @media (min-width: #{$laptop-large}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin max-desktop {
    @media (max-width: #{$desktop}) {
        @content;
    }
}