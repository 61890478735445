@import "base/index";

@include fontFace(HelveticaNeue, HelveticaNeue/HelveticaNeue-Regular, normal, normal);
@include fontFace(HelveticaNeue, HelveticaNeue/HelveticaNeue-Medium, 500, normal);

// :root {
//     --app-height: 100%;
// }

body {
    font-family: 'HelveticaNeue', 'sans-serif';
    background-color: #ff0000;
    color: #000;
    text-transform: uppercase;
    font-size: 11.6px;
    min-height: 100svh;
    // min-height: var( --app-height);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px;

    @include tablet {
        // min-height: 100vh;
        font-size: 21.6px;
    }
}

header,
main,
footer {
    width: 100%;
}

a {
    color: inherit;
}

.bold {
    font-weight: 500;
}

main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    max-width: 260px;
    width: 100%;
    margin-bottom: 10px;

    @include tablet {
        max-width: 480px;
        margin-bottom: 20px;
    }
}
